import {inject,bindingMode,bindable } from 'aurelia-framework';
import flatpickr from "flatpickr";
import { JwtService } from '../../../shared/services/jwtservice';
import { DialogController } from 'aurelia-dialog';
import {DialogService} from 'aurelia-dialog';
import {LeavemanagementService} from "../../../shared/services/leavemanagementservice";
import * as XLSX from 'xlsx';
import {config} from '../../../shared/services/config';
import { constants } from '../../../shared/services/constants';
import Scrollbar from 'smooth-scrollbar';
import {LoginService} from "../../../shared/services/loginservice";
import {Router} from 'aurelia-router';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import {ValidationControllerFactory,ValidationRules} from "aurelia-validation";
import { autoinject, observable } from 'aurelia-framework';
import {DashboardService} from "../../../shared/services/dashboardservice";


@inject(DialogController,JwtService,LeavemanagementService,DialogService,Router,LoginService,ValidationControllerFactory,DashboardService,Element)
export class PTOAnnouncement{
    pageSize = config.grid_PazeSize;
    searchTerm = '';
    search = '';
    showTable = false;
    FromDate = null;
    ToDate = null;
    isLoading = false;
   showEmployeeDropdown = false;
   FavoritesExist = false;
    Successmessages=false;
    dateDifferenceError = false;
    sitemapId=constants.ptoannouncementsitemapid;
    showDropdown = false;
    filteredEmployeesSummery = [];
   

    constructor(controller,JwtService,LeavemanagementService,DialogService,loginservice,router,controllerFactory,DashboardService,element){
        this.controller = controller;
        this.controllerFactory = controllerFactory.createForCurrentScope();

        this.jwtService= JwtService;
        this.dialogService = DialogService;
        this.empid = this.jwtService.getEmployeeId();
        this.leavemanagementService = LeavemanagementService;
        this.router = router;
        this.loginservice = loginservice;
        this.dashboardService = DashboardService;



    }
    active(){
        const searchButton = document.getElementById('search-button');
        searchButton.addEventListener('click', this.handleSearch);
    }
    async attached(){
        document.addEventListener('click', this.handleDocumentClick);
        const windowWidth = window.innerWidth;
        if (windowWidth <= 1280) {
            Scrollbar.init(document.querySelector('.disable-desktop-scroll'));
        }
        function formatDateWithLeadingZero(date) {
            const day = date.getDate();
            const month = date.getMonth() + 1; // Adding 1 because getMonth() returns zero-based month index
            const year = date.getFullYear();
            const formattedDay = day.toString().padStart(2, '0');
            const formattedMonth = month.toString().padStart(2, '0');
            return `${formattedDay}-${formattedMonth}-${year}`;
          }
        // Fetch the server's current date
        // Fetch the server's current date
        this.dashboardService.GetServerCurrentDate(this.empid)
            .then(data => {
                const apiDatetimeStr = data.CurrentDate;
                this.apiDatetime = new Date(apiDatetimeStr);

                // Use the API server's date as the From date
                const fromDate = new Date(apiDatetimeStr);

                flatpickr(this.datePicker, {
                    closeOnSelect: true,
                    dateFormat: 'd-m-Y',
                    minDate: null,
                    maxDate: null,
                    defaultDate: fromDate, // Set default date to API server's date
                    enableTime: false,
                    enableSeconds: false,
                    time_24hr: false,
                    minuteIncrement: 1,
                    hourIncrement: 1,
                    timeFormat: 'h:i K',
                    clickOpens: false,
                    onClose: (selectedDates, dateStr, instance) => {
                        this.From = dateStr; // Update the From date when selected
                    }

                });
                this.calendarIcon.addEventListener('click', () => {
                    this.datePicker._flatpickr.open();
                });

                this.FDate = formatDateWithLeadingZero(fromDate);
                this.From = this.FDate;
            })
            .catch(error => {
                console.error("Error fetching server current date:", error);
                // Handle error if necessary
            });

          const toDate = new Date();
          toDate.setDate(toDate.getDate()+7);

          this.TDate = formatDateWithLeadingZero(toDate);
          this.To = this.TDate;
        // flatpickr(this.datePicker, {
        //     closeOnSelect: true,
        //     dateFormat: 'd-m-Y',
        //     minDate: null,
        //     maxDate: null,
        //     defaultDate: fromDate,
        //     enableTime: false,
        //     enableSeconds: false,
        //     time_24hr: false,
        //     minuteIncrement: 1,
        //     hourIncrement: 1,
        //     timeFormat: 'h:i K',
        //     clickOpens:false,
        //     onClose: function (selectedDates, dateStr, instance) {
        //         this.fromDate = dateStr;
        //     }
        // });
        // this.calendarIcon.addEventListener('click', () => {
        //     this.datePicker._flatpickr.open();
        // });
        flatpickr(this.datePickerss, {
            closeOnSelect: true,
            dateFormat: 'd-m-Y',
            minDate: null,
            maxDate: null,
            defaultDate: toDate,
            enableTime: false,
            enableSeconds: false,
            time_24hr: false,
            minuteIncrement: 1,
            hourIncrement: 1,
            timeFormat: 'h:i K',
            clickOpens:false,
            onClose: function (selectedDates, dateStr, instance) {
                this.toDate = dateStr;
            }
        });
        this.calendarIconEnd.addEventListener('click', () => {
            this.datePickerss._flatpickr.open();
        });
        this.leavemanagementService.DepartmentsForDropdown()
            .then(response => {
                this.departments = response;
                return false;
            });
             this.allEmployees =await this.leavemanagementService.GetEmployeemasterDetails();

        const timeoff= {
            //EmployeeId:this.empid,
            From:this.From,
            To:this.To
        };
        this.isLoading = true;
        this.leavemanagementService.GetEmployeePTOAnnouncemnt(timeoff).then(data=>
        {
            this.filteredEmployeesSummery=data.List;
            this.isLoading = false;
        });
       
        this.leavemanagementService.GetPersonFavoriteItems(this.empid,this.sitemapId)
        .then(data=>{
            this.FavoritesExist=data;
        });
        this.addToRecentItems();
    }
    detached() {
        document.removeEventListener('click', this.handleDocumentClick);
    }



    handleDocumentClick = (event) => {
        const dropdownContainer = this.dropdownContainer;
        if (dropdownContainer && !dropdownContainer.contains(event.target)) {
            this.showDropdown = false;
        }
    }


    async departmentSelected() {
        if (this.selectedDepartment !== "Select") {
          
            this.allEmployees = await this.leavemanagementService.GetEmployeeNamesByDepartmentID(this.selectedDepartment);
        } else {
            
            this.allEmployees = [];
        }
    }
    dateSort(a, b, sortOrder) {
        let date1 = new Date(a.registered);
        let date2 = new Date(b.registered);
        if (date1 === date2) {
            return 0;
        }
        if (date1 > date2) {
            return 1 * sortOrder;
        }
        return -1 * sortOrder;
    }
    nameLength(row) {
        return row.Type.length;
    }

    popupViewTimeOffRequest(leaveAppId,PhotoPath){
        const approveData = {
            selectedRecordId : leaveAppId,
            PhotoPath:PhotoPath,
        };
        this.dialogService.open({viewModel: Viewtimeoffrequestcomponent, model:approveData})
    }

    async handleSearch() {
        if (!this.searchTerm) {
            this.filteredResults = [];
            this.selectedEmployee = "";
          return;
        }
        this.filteredResults = this.allEmployees.filter(employee =>
          employee.EmployeeName.toLowerCase().includes(this.searchTerm.toLowerCase())
        );
        // Check if filteredResults is empty
        if (this.filteredResults.length === 0) {
            this.selectedEmployee = "";
            const errorInfo = {
                error: {
                    message: "Employee not found"
                }
            };
            this.showNoResultsMessage = [errorInfo];
            //this.showNoResultsMessage = true; // Set the flag to display the message
        } else {
            this.showNoResultsMessage = []; // Reset the flag
        }
        this.showDropdown = true;

      }
      async handleSelection(employee) {
        //this.showTree = false;
              this.searchTerm = employee.EmployeeName;
              this.selectedEmployee = employee;
              this.EmployeeError = '';
              this.showDropdown = false; // Close the dropdown after selection
      }
    handleDocumentClick = (event) => {
        const dropdownContainer = this.dropdownContainer;
        if (dropdownContainer && !dropdownContainer.contains(event.target)) {
            this.showDropdown = false;
        } };



    Search() {
        this.showTable = true;
        this.errors = null;
        this.controllerFactory.validate()
            .then(result => {
                if (result.valid) {
                    if (this.From && this.To) {
                        const fromParts = this.From.split('-');
                        const toParts = this.To.split('-');
                        const fromDate = new Date(fromParts[2], fromParts[1] - 1, fromParts[0]);
                        const toDate = new Date(toParts[2], toParts[1] - 1, toParts[0]);

                        const today = new Date(); // Get today's date
                        const differenceInTime = toDate.getTime() - fromDate.getTime();
                        const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
                        if (fromDate > toDate) {
                            this.dateDifferenceError = "From date should be less than to date.";
                        } else {
                            this.dateDifferenceError = "";
                        }
                    }
                    if(!this.dateDifferenceError) {
                        this.dateDifferenceError = null;
                        if (this.selectedEmployee && this.selectedEmployee.EmployeeID) {
                            const timeoff = {
                                Fromdate: this.From,
                                Todate: this.To,
                                DepartmentID: this.selectedDepartment,
                                EmployeeId: this.selectedEmployee.EmployeeID
                            };
                            this.isLoading = true;
                            this.leavemanagementService.GetEmployeePTOAnnouncemnt(timeoff).then(data => {
                                this.filteredEmployeesSummery = data.List;
                                this.isLoading = false;
                                this.showNoResultsMessage = [];
                                this.dateDifferenceError = null;
                            }).catch(error => {
                                console.error("Error fetching data for selected employee:", error);
                                this.isLoading = false;
                            });
                        } else {
                            const timeoff = {
                                Fromdate: this.From,
                                Todate: this.To,
                                DepartmentID: this.selectedDepartment
                            };
                            this.isLoading = true;
                            this.leavemanagementService.GetEmployeePTOAnnouncemnt(timeoff).then(data => {
                                this.filteredEmployeesSummery = data.List;
                                this.isLoading = false;
                                this.showNoResultsMessage = [];
                                this.dateDifferenceError = null;
                            }).catch(error => {
                                console.error("Error fetching data:", error);
                                this.isLoading = false;
                            });
                        }
                    }
                }
            }).catch(error => {
            console.error("Validation error:", error);
        });
    }


    Reset(){
       this.selectedDepartment ="";
       this.searchTerm = ''; 
       this.selectedEmployee = [];
       this.selectedEmployee ="";
        this.showNoResultsMessage = [];
        this.dateDifferenceError = false;
       this.attached();
       this.FromError="";
        this.errors = null; // Clear any validation errors here
        // Reset validation state
        this.ToError = null; // Reset ToError property as well
    }
    exportData() {
        const data = this.filteredEmployeesSummery;
        const filename = 'PTOAnnouncement';
        this.exportToExcel(data, filename);
    }
    exportToExcel(data, filename) {
        const headers = {
            Employee: 'Employee',
            Department: 'Department',
            Fromdate: 'From date',
            Todate: 'To date',
            Amount: 'Amount (in days)',
           
        };
        const filteredData = data.map(employee => {
            return {
                Employee: employee.EmployeeName,
                Department:employee.DepartmentName,
                Fromdate: employee.From,
                Todate: employee.To,
                Amount: employee.Amount
            };
        });

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet 1');
       const headerStyle = {
            font: { bold: true, color: { argb: 'FFFFFF' } },
            fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFA07A' } }
        };

        const headerRow = worksheet.addRow(Object.values(headers));
        headerRow.eachCell(cell => {
            cell.font = headerStyle.font;
            cell.fill = headerStyle.fill;
        });
    
        filteredData.forEach(employee => {
            const dataRow = worksheet.addRow(Object.values(employee));

        });
        worksheet.views = [
            { showGridLines: true }
        ];
        workbook.xlsx.writeBuffer().then(buffer => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(blob, `${filename}.xlsx`);
        });
    }

    addToFavourites(){
        this.leavemanagementService.AddToFavourites(this.empid,this.sitemapId).then(data=>
         {

             window.location.reload();
         });
 
     }

     addToRecentItems(){    
        this.leavemanagementService.AddToRecentItems(this.empid,this.sitemapId).then(data=>
         {
          // window.location.reload();

         });
     }

   
    
   
   
}
