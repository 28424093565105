import { inject } from 'aurelia-framework';
import {App} from "../../../app";
import {Router} from 'aurelia-router';
import {OrganizationService} from "../../../shared/services/organizationservice";
import {DialogController} from "aurelia-dialog";
import {JwtService} from "../../../shared/services/jwtservice";
import {LoginService} from "../../../shared/services/loginservice";

@inject(App,Router,OrganizationService,DialogController,JwtService,LoginService)

export class OrgChartPage{
    formData={};
    treeChart = [];
    managerChild = [];
    ManagerId = '';
    employeeChildTree = [];
    filteredResults = [];
    employeeTree = [];
    alldata = [];
    routerView = "main-content";
    constructor(App,router,organizationService,DialogController,jwtService,loginService) {
        this.app=App;
        this.router = router;
        this.organizationService = organizationService;
        this.controller = DialogController;
        this.ManagerId = 637;
        this.jwtService = jwtService;
        this.loginService = loginService;
    }
    attached() {
        // if(this.jwtService.getToken() === undefined){
        //     this.loginService.purgeAuth();
        //     this.router.navigateToRoute('login')
        // }
        this.organizationService.GetEmployeemasterDetails().then(data => {
            this.alldata = data;
            const filteredItems = this.alldata.filter(item => item.ReportingManagerID === this.ManagerId);
            const list = document.createElement('ul');
            filteredItems.forEach((itemData, index) => {
                const item = document.createElement('li');
                item.classList.add(`dynamic-li-${index}`);
                const container = document.createElement('div');
                const image = document.createElement('img');
                image.src = itemData.PhotoPath;
                image.alt = itemData.EmployeeName;
                image.width = '20';
                image.height = '20';
                container.appendChild(image);
                const name = document.createTextNode(itemData.EmployeeName);
                container.appendChild(name);
                item.appendChild(container);
                container.addEventListener('click', () => {
                    item.classList.toggle('ui-state-active');
                    const clickedEmployee = item.textContent;
                    const filteredID = this.alldata.filter(item => item.EmployeeName === clickedEmployee);
                    try{
                        const EmpID = filteredID[0].EmployeeID;
                        const currentEmpName = filteredID[0].EmployeeName;
                        const filtered = {id:EmpID,name:currentEmpName};
                        this.showChild(filtered);
                    }catch(error){

                    }
                });
                list.appendChild(item);
            });
            const ulClass = `dynamic-ul-${Date.now()}`;
            list.classList.add(ulClass);
            const container = document.querySelector('#list-container');
            container.innerHTML = '';
            container.appendChild(list);
        });
    }
    async showChild(emp) {
        let nestedCounter = 0;
        const childTree = this.alldata.filter(item => item.ReportingManagerID === emp.id);
        const nestedList = document.createElement('ul');
        const nestedListClass = `nested-list-${emp.id}`;
        nestedList.classList.add(nestedListClass);
        childTree.forEach((itemData, index) => {
            const item = document.createElement('li');
            const liClass = `nested-li-${emp.id}-${index}`;
            item.classList.add(liClass);
            const container = document.createElement('div');
            const image = document.createElement('img');
            image.src = itemData.PhotoPath;
            image.alt = itemData.EmployeeName;
            image.width = '20';
            image.height = '20';
            container.appendChild(image);
            const name = document.createTextNode(itemData.EmployeeName);
            container.appendChild(name);
            item.appendChild(container);
            container.addEventListener('click', () => {
                item.classList.toggle('ui-state-active');
                const clickedEmployee = item.textContent;
                const filteredID = this.alldata.filter(item => item.EmployeeName === clickedEmployee);
                try {
                    const EmpID = filteredID[0].EmployeeID;
                    const currentEmpName = filteredID[0].EmployeeName;
                    const filtered = { id: EmpID, name: currentEmpName };
                    this.showChild(filtered);
                } catch (error) {

                }
            });
            nestedList.appendChild(item);
        });
        const listItems = document.querySelectorAll('li');
        let container;
        listItems.forEach(listItem => {
            if (listItem.textContent === emp.name) {
                container = listItem;
            }
        });
        container.appendChild(nestedList);
    }
    cancel() {
        this.controller.cancel();
    }
    goBack(){
        this.router.navigateToRoute('organization');
    }
}
