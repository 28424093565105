import { inject } from 'aurelia-framework';
import OrgChart from '@balkangraph/orgchart.js';
import {App} from "../../../app";
import {Router} from 'aurelia-router';
import {JwtService} from "../../../shared/services/jwtservice";
import {LoginService} from "../../../shared/services/loginservice";

@inject(App,Router,JwtService,LoginService)

export class ChartPage{
    formData={}
    constructor(App,router,jwtService,loginService) {
        this.app=App;
        this.router = router;
        this.jwtService = jwtService;
        this.loginService = loginService;
    }
    attached() {
        // if(this.jwtService.getToken() === undefined){
        //     this.loginService.purgeAuth();
        //     this.router.navigateToRoute('login')
        // }
        this.app.updatePropertyValue(false);
        const chartData = JSON.parse(localStorage.getItem('chartData'));
        let chart = new OrgChart(document.getElementById("orgChart"), {
            pan: false,
            draggable: false,
            zoom: false,
            levelSeparation: 60,
            siblingSeparation: 20,
            nodeSpacing: 10,
            nodeBinding: {
                field_0: "name",
                field_1:"departmentName",
                field_2:"positionLevel",
                img_0: "img",

            },
            nodes: chartData,
            css: {
                "node": {
                    "padding": "10px",
                    "line-height": "1.5"
                }
            }
        });
    }
    goBack(){
        this.router.navigateToRoute('organizationchart');
    }
}
