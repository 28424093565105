import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {DialogService} from 'aurelia-dialog';
import { OrganizationService } from '../../shared/services/organizationservice';
import { config } from '../../shared/services/config';
import {OrgChartPage} from "./organizationchart/orgchartcomponent";
import { JwtService } from '../../shared/services/jwtservice';
import { constants } from '../../shared/services/constants';
import {LoginService} from "../../shared/services/loginservice";

@inject(OrganizationService,DialogService,Router,JwtService,LoginService)
export class OrganizationComponent {
    pageSize = config.grid_PazeSize;
    policiesdata = [];
    $displayData = [];
    originalData = [];
    showNoRecordsFound = false;
    announcements = [];
    AnnouncementsSiteMapId=constants.announcementsSitemapId;
    PoliciesSiteMapId=constants.policiessitemapId;

    constructor(OrganizationService,DialogService,router,jwtService,loginservice){
        this.organizationService = OrganizationService;
        this.dialogService = DialogService;
        this.router = router;
        this.jwtService = jwtService;
        this.loginservice = loginservice;


    }

    empid= window.localStorage['EmployeeID'];
    searchText = "";

    async activate() {
        try {
            const data = await this.organizationService.GetPoliciesData(this.empid);
            this.policiesdata = data;
            this.originalData = data.slice();
            this.applySearchFilter();
        } catch (error) {
            console.error("Error fetching policies data:", error);
        }
    }

    getCurrentRouteURL() {
      const currentInstruction = this.router.currentInstruction;
      if (currentInstruction) {
        const routeURL = currentInstruction.fragment;
        const sidebarListItem = document.querySelector('.sidebar ul li.organization');
        if (sidebarListItem) {
          const subActiveItems = document.querySelectorAll('.sidebar ul li.sub-active');
          subActiveItems.forEach(item => item.classList.remove('sub-active'));
          const activeLinkItems = document.querySelectorAll('.sidebar ul li.active-link');
          activeLinkItems.forEach(item => item.classList.remove('active-link'));
          sidebarListItem.classList.add('sub-active');
        }
        const sidebarLink = document.querySelector('.sidebar ul li.organization .sidebar-sub .announcement a');
        if (sidebarLink) {
          sidebarLink.classList.add('active-link');
        }
        return routeURL;
      }
      return '';
    }

    attached() {
        this.getCurrentRouteURL();
        const searchInput = document.querySelector('input[name="Find Employee"]');
        searchInput.addEventListener('input', () => {
            this.searchTextChanged();
        });
        this.organizationService.GetPoliciesData(this.empid).then(data => {
            this.policiesdata = data;
            this.applySearchFilter();
            this.Files =  this.policiesdata.map(option => option.Path);
            if ( this.policiesdata.map(option => option.Path)) {
                this.file =  this.policiesdata.map(option => option.Path);
                this.DocumentName = this.policiesdata.map(option => option.Path);
            }
        });
        this.organizationService.GetEventTypeDropdown().then(data => {
            this.eventTypes = data;
        });
        this.EventID = 0;
        this.organizationService.GetAnnouncementsData(this.EventID)
            .then(data => {
                this.announcements = data;
            })


           this.addToRecentItems();
           this.PoliciesaddToRecentItems();
    }

    addToRecentItems(){    
        this.organizationService.AddToRecentItems(this.empid,this.AnnouncementsSiteMapId).then(data=>
         {
          // window.location.reload();
              
         });
     }

     PoliciesaddToRecentItems(){    
        this.organizationService.AddToRecentItems(this.empid,this.PoliciesSiteMapId).then(data=>
         {
              
         });
     }

    getFileNameFromPath(filePath) {
        const lastIndex = filePath.lastIndexOf('/');
        const fileName = filePath.substring(lastIndex + 1);
        return fileName;
    }
    downloadNewFile(filePath) {
        fetch(filePath)
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                const downloadUrl = config.img_url + filePath;
                link.href = downloadUrl;
                link.download = downloadUrl;
                link.target = '_blank';
                link.click();
                window.URL.revokeObjectURL(url);
            })
            .catch(error => {
                console.error('Error downloading file:', error);
            });
    }
    applySearchFilter() {
        if (!this.searchText) {
            this.$displayData = this.originalData.slice();
            this.pageSize = config.grid_PazeSize;
        } else {
            this.$displayData = this.policiesdata.filter((policy) => {
                return policy.PolicyName.toLowerCase().includes(this.searchText.toLowerCase());
            });
        }
        this.showNoRecordsFound = this.$displayData.length === 0;
    }
    searchTextChanged() {
        this.applySearchFilter();
        if(this.searchText.length === 0){
            this.pageSize = config.grid_PazeSize;
            this.attached();
        }
    }
    downloadFile(path) {
        if(path){
            const downloadUrl = config.img_url + path;
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.target = '_blank';
            link.download = downloadUrl;
            link.click();

        } else {
            this.validationmessage = 'Path is empty. Cannot download file.';
            console.log(this.validationmessage)
        }
    }
    filterAnnouncements(eventid) {
        const updatedid = eventid;
        if(eventid === 'Select'){
            this.EventID = 0;
            this.organizationService.GetAnnouncementsData(this.EventID)
                .then(data => {
                    this.announcements = data;
                })
        }
        this.organizationService.GetAnnouncementsData(eventid)
            .then(data => {
                this.announcements = data;
            })
    }
    openOrgModal(){
        this.dialogService.open({viewModel:OrgChartPage,lock: false})
            .whenClosed((feedback) => {
                if(!feedback.wasCancelled){
                }else{
                    this.searchTerm = '';
                    this.employeeTree = [];
                }
            });
    }

   
}
